.grid {
  border-bottom: 1px solid #eee;
  margin-bottom: 48px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 12px 0;
  border-top: 1px solid #eee;

  img {
    width: 180px;
  }
}

.options {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.loadMore {
  margin-top: -36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 600px) {
  .item {
    flex-direction: row;
  }

  .options {
    margin-top: 0;
    flex-direction: row;
  }
}
