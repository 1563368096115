.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
}

.item {
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 480px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 640px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
