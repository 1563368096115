.container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  box-sizing: border-box;
  padding: 36px;
  box-shadow: 0 14px 28px rgba(#000, 0.1);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  background-color: #f9f9f9;
  border-radius: 8px;

  span {
    padding: 12px 0;
  }
}

@media (min-width: 400px) {
  .header {
    flex-direction: row;
    align-items: center;

    span {
      padding: 0 24px;
    }
  }
}
