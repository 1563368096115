.button {
  display: inline-block;
  padding: 18px 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 12px;
  background-color: #900;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: darken(#900, 5%);
    text-decoration: underline;
  }

  &:active {
    background-color: darken(#900, 15%);
  }

  &:disabled {
    opacity: 0.5;
    background-color: #900;
    text-decoration: none;
    cursor: not-allowed;
  }
}

.link {
  display: inline-block;
  padding: 18px 36px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border: none;
  border-radius: 12px;
  outline: none;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & + & {
    margin-left: 4px;
  }
}

.link + .link,
.link + .button,
.button + .link,
.button + .button {
  margin-left: 4px;
}
