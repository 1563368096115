.container {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}

.viewport {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 18px;
}

.canvas {
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(#000, 0.1);
  zoom: 0.33;
}

.hide {
  display: none;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 528px;
  width: 100%;
  box-sizing: border-box;
}

.options textarea {
  width: 100%;
  border-radius: 8px;
  background-color: #f2f2f2;
  border: none;
  box-sizing: border-box;
  padding: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}

.uploader {
  position: relative;
  display: inline-block;
  padding: 18px 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
  line-height: 18px;
  border-radius: 12px;
  background-color: #900;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: darken(#900, 5%);
    text-decoration: underline;
  }

  &:active {
    background-color: darken(#900, 15%);
  }

  &.alt {
    background-color: #444;

    &:hover {
      background-color: darken(#444, 5%);
    }

    &:active {
      background-color: darken(#444, 15%);
    }
  }

  input {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.uploadOptions {
  display: flex;
  flex-direction: column;
  align-items: center;

  .uploader + button {
    margin-top: 4px;
  }
}

.label {
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.counter {
  font-size: 0.75em;
  color: #999;
}

@media (min-width: 480px) {
  .canvas {
    zoom: 0.5;
  }

  .uploadOptions {
    flex-direction: row;

    .uploader + button {
      margin-left: 4px;
    }
  }
}

@media (min-width: 720px) {
  .canvas {
    zoom: 0.66;
  }
}
