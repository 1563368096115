.fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container {
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.form input {
  width: 100%;
  border-radius: 8px;
  background-color: #f2f2f2;
  border: none;
  box-sizing: border-box;
  padding: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}
