.container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  box-sizing: border-box;
  padding: 36px;
  box-shadow: 0 14px 28px rgba(#000, 0.1);
}
